import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type setSlot = {
  // using `interface` is also ok
  slot: number;
};
type MyProps = {
  setSlot: CallableFunction;
  slot: number;
};

type MyState = {
  index: number;
};

const slots: number[] = [100, 200, 300, 400, 500, 600, 700, 800,900];

export default class Header extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      index: 1
    };
  }

  static getDerivedStateFromProps(props: MyProps, state: MyState) {
    const newSlotMag = Math.floor(props.slot/100)*100;
    if (newSlotMag !== slots[state.index]) {
      const newIndex = slots.indexOf(newSlotMag);
      return {index: newIndex};
    }
    return null;
  }

  handleChange(event: React.SyntheticEvent, newIndex: number){
    this.setState({index: newIndex});
    this.props.setSlot(slots[newIndex])()
  }

  render () {
    return ( 
      <Box sx={{margin:0, pt:0, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={this.state.index} onChange={this.handleChange.bind(this)} aria-label="Slots">
          {slots.map(s => <Tab key={Math.random()} label={s}/>)}
        </Tabs>
      </Box>
    );
  }
}