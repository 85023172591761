import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import theme from './theme';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export function CLink(props: any) {
  return (  
    <Paper sx={{marginLeft: 0, marginTop: 2, marginBottom:2}} elevation={0} onClick={props.setSlot(props.pg)}>
        <Stack spacing={1} direction="row">
          <Typography
            sx={{color: (theme: any) => theme.palette.text.secondary, width: '250px'}}
            dangerouslySetInnerHTML={{
              __html: props.t
            }}
            />
          <Link onClick={props.setSlot(props.pg)}>{props.txtRight || props.pg}</Link>
        </Stack>

      </Paper>
  );
}

export function CText(props: any) {
  return (
    //Typography sx={{ mt: 6, mb: 3 }} color="text.secondary">
    <Typography 
        sx={{color: (theme: any) => theme.palette.text.secondary}}
        dangerouslySetInnerHTML={{ __html: props.t }}/>
    //</Typography>
  );
}

export function CMono(props: any) {
  return (
    //Typography sx={{ mt: 6, mb: 3 }} color="text.secondary">
      //<div sx={{color: (theme: any) => theme.palette.main, whiteSpace: 'pre',  fontFamily: 'monospace, monospace'}} dangerouslySetInnerHTML={{ __html: props.t }}/>
      <Typography
        sx={{color: (theme: any) => theme.palette.text.secondary, whiteSpace: 'pre',  fontFamily: 'monospace, monospace'}}
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: props.t
        }}
      />
    //</Typography>
  );
}

export function CTitle(props: any) {
  return (
    <Typography sx={{ mt: 0, mb: 3 }} color="text.secondary">
      <h4>{props.t}</h4>
    </Typography>
  );
}

export function CTable(props: any) {
  return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
            {props.tr.map((row: any) => (
              <TableRow
                key={Math.random()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                {row.td.map((d: any) => <TableCell colSpan={d.sp}>{d.t}</TableCell> )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}


export function CImage(props: any) {
  /*
  https://vtx-origin.ard.de/images/frontend/4/6/8/9/468908f7-768a-40e6-b559-07355e9cf763.jpeg
                         ../images/frontend/1/2/f/9/12f94752-7146-448e-a8c0-c7da3e931a98.jpeg
  */
  return (
    <div>
      <Typography
          sx={{color: (theme: any) => theme.palette.text.secondary}}
          dangerouslySetInnerHTML={{
            __html: props.title
          }}
          />
      <img width="100%" src={`https://vtx-origin.ard.de/images/${props.img}`}/>
      <Typography
          sx={{color: (theme: any) => theme.palette.text.secondary}}
          dangerouslySetInnerHTML={{
            __html: props.foot
          }}
          />
    </div>
  );
}

export function CList(props: any) {
  return (
      <ul>
      {props.li.map((i: any) => 
        <li>
          <Typography
              sx={{color: (theme: any) => theme.palette.text.secondary}}
              dangerouslySetInnerHTML={{
                __html: i
              }}/>
        </li>
      )}
      </ul>
  );
}

export function CEpg(props: any) {
  const d = props.epg[0];
  return (  
    <Paper sx={{marginTop: 2, marginBottom:2}} elevation={0}>
      <Stack spacing={1} direction="row">
        <Typography color="text.secondary">
          {d.tim}
        </Typography>
        <Typography  sx={{width: '195px'}}color="text.secondary">
          {d.t}
        </Typography>
        <Typography color="text.secondary">
          {d.amend}
        </Typography>
        <Link onClick={props.setSlot(props.pg)}>{props.txtRight || props.pg}</Link>
      </Stack>
  </Paper>
);
}