import * as React from 'react';
import { MenuUnstyledProps } from '@mui/base';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {CLink, CText, CTitle, CTable, CMono, CImage, CList, CEpg} from './Types';
import Paper from '@mui/material/Paper';
import SlotInput from './SlotInput'

type MyProps = {
	data: any,
	slot: number,
	setSlot: CallableFunction,
	setRfa: CallableFunction,
	rfa: string
};

export default class SlotView extends React.Component<MyProps> {
	constructor(props: MyProps) {
		super(props);
	}

	render () {
		const createPage = (sub: any, i: number) => {
			//Sub-Pages
			const Header =  
			<Typography color="text.secondary">
				<Stack spacing={1} direction="row">
					<h3 style={{marginTop: 3, marginBottom: 4}}>{sub.header}</h3> 
					<div style={{marginTop: 6}}>{i > 0 ? `(${i+1}. Sub-Page)` : ''}</div>
				</Stack>
			</Typography>
			return [Header].concat(sub.c.map(dseMapper))
		}

		const dseMapper = (dse: any) => {
			switch (dse.ctype) {
				case 'link': return <CLink {...dse.link} setSlot={this.props.setSlot}/>; break;
				case 'text': return dse.mono ? 
					<CMono {...dse} /> :
					<CText {...dse} />; break;
				case 'title': return <CTitle {...dse}/>; break;
				case 'table': return <CTable {...dse}/>; break;
				case 'image': return <CImage {...dse}/>; break;
				case 'epglink': return <CEpg {...dse}  setSlot={this.props.setSlot}/>; break;
				case 'spacer': return <div>&nbsp;</div>; break;
				case 'list': return <CList {...dse} />; break;
				default: return <pre>{JSON.stringify(dse, undefined, 2)}</pre>
			}
		}

		const pageWrapper = (page: any) => <Paper sx={{m:1, p:2}} variant="outlined">{page}</Paper>

		const d = this.props.data;
		const SlotContent = () => (
			<div>{
				(d && d.sub && d.sub.map(createPage).map(pageWrapper)) || 
				<div>No Content for this Slot?</div>
			}</div>)

		const CHeader = () =>  <SlotInput 
									setSlot={this.props.setSlot} 
									rfa={this.props.rfa} 
									setRfa={this.props.setRfa} 
									slot={this.props.slot} 
									prev={this.props.data.prevPg} 
									next={this.props.data.nextPg}
								/>

		return ( 
			<div>
				<CHeader />
				<SlotContent />
			</div>
		);
	}
}