import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import Header from './Header';
import SlotView from './SlotView';

function Copyright(props: any) {
  return (
    <Typography sx={{ mt: 4, mb: 3 }} variant="body2" color="text.secondary" align="center">
      <Link target="new" href={`https://vtx-origin.ard.de/app/index.html?html5=1&demo=1&slot=${props.slot}&mandant=${props.rfa}`}>{props.rfa} slot {props.slot} in hbbTV</Link><br/><br/>
      {//'Copyright © '
        'VTX-Spellcheck and Monitoring '
      }
      <Link color="inherit" href="https://ard-poc.de/">
       ARD-POC
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

type setSlot = {
  // using `interface` is also ok
  slot: number;
};

type data = {
  // using `interface` is also ok
  data: any;
};
type MyState = {
  count: number; // like this
  data: any;
};
type sampleProps = {

}

export default class App extends React.Component<any, any> {
  constructor(props: sampleProps) {
    super(props);
    this.state = {
      date: new Date(), 
      loggedIn: false,
      data: {},
      slot: 100,
      rfa: 'ard'
    };
    this.fetch();
    this.setSlot = this.setSlot.bind(this);
    this.setRfa = this.setRfa.bind(this);
  }

  fetch() {
    const that = this;
    fetch(`https://vtx-view.ard-poc.de/p/${this.state.rfa}/${this.state.slot}.json`)
    .then(response => response.json())
    .then(data => that.setState({data}))
  }

  setSlot(slot: number) {
    const that = this;
    return () => {
      console.log({slot})
      that.setState({slot}, this.fetch)
    }
  }

  setRfa(rfa: string) {
    const that = this;
    return () => {
      console.log({rfa})
      that.setState({rfa}, this.fetch)
    }
  }

  handleKeyDown(e: any){
    if(!(e && e.code)) return;
    if(['ArrowLeft', 'ArrowDown'].includes(e.code)) 
      this.setSlot(this.state.slot-1)(); 
    if(['ArrowRight', 'ArrowUp'].includes(e.code)) 
      this.setSlot(this.state.slot+1)(); 
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
  }

  render() {
    return (
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Header setSlot={this.setSlot} slot={this.state.slot}/>
          <SlotView data={this.state.data} slot={this.state.slot} setSlot={this.setSlot} setRfa={this.setRfa} rfa={this.state.rfa}/>
          <Copyright slot={this.state.slot} rfa={this.state.rfa}/>
        </Box>
      </Container>
    );
  }
}