import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
const { useState } = React;

export default function SlotInput(props: any) {
    const [slot, setSlot] = useState(props.slot);
    const [rfa, setRfa] = useState(props.rfa);

    function handleChange(e: any) {
        console.log("change: ", e.target.value)
        setSlot(e.target.value);
    }

    function handleChangeRfa(e: any) {
        //console.log("change: ", e.target.value)
        props.setRfa(e.target.value)();
        setRfa(e.target.value);
    }

    function handleKeyDown(e: any) {
        console.log('key down: ', e.key)
        //e.preventDefault();
        //setSlot(e.target.value);
        if(e.key === 'Enter')
            props.setSlot(slot)();
    }

    return (
        <Box sx={{p:1, marginTop:2,marginBottom:2 }}>
            <Stack spacing={2} direction="row">
                <FormControl style = {{width: 80}}>
                    <InputLabel id="demo-simple-select-label">Rfa</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Rfa"
                    value={rfa}
                    onChange={handleChangeRfa}
                    >
                    <MenuItem value="ard" selected>ARD</MenuItem>
                    <MenuItem value="br">BR</MenuItem>
                    <MenuItem value="rbb">rbb</MenuItem>
                    <MenuItem value="ndr">NDR</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="outlined" onClick={props.setSlot(props.prev)}>&lt;</Button>
                <TextField    
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    value={slot}
                    //defaultValue={slot}
                    id="outlined-number"
                    label="Slot"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style = {{width: 70}}
                />
                <Button variant="outlined" onClick={props.setSlot(props.next)}>&gt;</Button>
            </Stack>
        </Box>
        );
}